// test id: 1155080
// variation id: 1431992
import Button from 'aac-components/components/Button';
import Modal from 'aac-components/components/Modal';
import {useState} from 'react';
import styled from 'styled-components';
import {GetHelpModalContentDesktop} from '../../GetHelpModalContent';

const GetHelpModalDesktop = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <Button
                style={{padding: '12px'}}
                onClick={() => setShowModal(true)}
                title="Get Help">
                Get Help
            </Button>
            {showModal && (
                <ModalStyles>
                    <Modal>
                        <GetHelpModalContentDesktop setShowModal={setShowModal} />
                    </Modal>
                </ModalStyles>
            )}
        </div>
    );
};
export default GetHelpModalDesktop;

const ModalStyles = styled.div`
    .modal .inner {
        border: 1px solid red;
    }
`;
