import Image from 'next/image';
import CallrailLink from '../CallrailLink';
import {useContext} from 'react';
import AppContext from '../AppContext';
import {BREAKPOINT} from '../../lib/styles';
import CallrailButton from '../CallrailButton';

const StatesNotListedCta = () => {
    const {isMobile, callTrackingNumber} = useContext(AppContext);
    return (
        <div className="states_not_listed__container">
            <Image
                src={
                    isMobile
                        ? '/static/Travel-for-rehab-background-mobile-min.jpg'
                        : '/static/Travel-for-rehab-background-min.jpg'
                }
                fill
                alt="American Addiction Centers Image"
                sizes="100vw"
                style={{objectFit: 'cover', opacity: '.8'}}
            />
            <div className="not_listed_cta__copy">
                <div className="not_listed_cta__title">
                    Sometimes the best treatment for you won't be near home.
                </div>
                <div className="not_listed_cta__content">
                    Traveling for rehab can give you the opportunity to start a new
                    chapter in your life and overcome addiction in a supportive and
                    healing environment. Call us today to start your journey to recovery.
                </div>
                <div
                    style={{
                        margin: '16px auto',
                        textAlign: 'center',
                        maxWidth: 'fit-content',
                    }}>
                    <CallrailButton />
                </div>
            </div>
            <style jsx>
                {`
                    .states_not_listed__container {
                        position: relative;
                        border: 1px solid var(--secondary-200);
                        border-radius: 8px;
                        margin-bottom: 40px;
                        overflow: hidden;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .states_not_listed__container {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: auto;
                            margin-bottom: 30px;
                        }
                    }

                    .not_listed_cta__title {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 28px;
                        text-align: center;
                        margin-bottom: 16px;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .not_listed_cta__title {
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 28px;
                            text-align: center;
                            color: #1a1a1a;
                            margin-bottom: 16px;
                        }
                    }

                    .not_listed_cta__content {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        margin-bottom: 15px;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .not_listed_cta__content {
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            color: #1a1a1a;
                            margin-bottom: 40px;
                        }
                    }

                    .not_listed_cta__copy {
                        position: relative;
                        padding: 40px 24px;
                        border-radius: 8px;
                    }
                `}
            </style>
        </div>
    );
};
export default StatesNotListedCta;
