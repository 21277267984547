import {zIndex} from 'aac-components/utils/helpers';
import Link from 'next/link';
import styled from 'styled-components';
import CallrailButton from '../../CallrailButton';

const GetHelpMobileCta = () => {
    return (
        <div className="get-help">
            <div>
                <div className="get-help__title">Admissions</div>
                <div className="get-help__copy">
                    Recovery starts now. Start your admission process online today.
                </div>
                <LinkStyles>
                    <a href="/admissions" title="Start Admissions">
                        Start Admissions
                    </a>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Paying for Treatment</div>
                <div className="get-help__copy">
                    Treatment is an investment in your future. You have options.
                </div>
                <LinkStyles>
                    <a href="/payment-options" title="Insurance & Payment Options">
                        Insurance & Payment Options
                    </a>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Contact Us</div>
                <div className="get-help__copy">Get in contact with us online.</div>
                <LinkStyles>
                    <a href="/contact-us" title="Contact Us">
                        Contact Us
                    </a>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Need Help?</div>
                <div className="get-help__copy">Your recovery can start today!</div>
                <CallrailButton style={{margin: '0 auto'}} />
            </div>
            <style jsx>{`
                .get-help {
                    position: fixed;
                    top: 266px;
                    bottom: 0;
                    overflow-y: scroll;
                    z-index: 100;
                    background: #fff;
                    width: 100%;
                    text-align: center;
                }
                .get-help > div {
                    border-bottom: 1px solid var(--gray-300);
                    padding: 24px 15px;
                }
                .get-help__title {
                    font-size: 18px;
                    margin-bottom: 24px;
                    color: var(--primary-500);
                    font-weight: bold;
                }
                .get-help__copy {
                    margin-bottom: 24px;
                    line-height: 24px;
                }
            `}</style>
        </div>
    );
};
export default GetHelpMobileCta;

const LinkStyles = styled.div`
    a {
        font-weight: bold;
        text-decoration: none;
    }
`;
