// test id: 1155080
// variation id: 1431992

import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {useState} from 'react';
import SharedModalContent from '../../GetHelpModalContent/SharedModalContent';
import OutsideClick from 'aac-components/components/OutsideClick';
import ABTest from '../../ABTest';
import GetHelpMobileCta from '../AAC2-5772/GetHelpMobileCta';

const GetHelpModalMobile = () => {
    const [isCtaOpen, setIsCtaOpen] = useState(false);

    return (
        <div className="cta">
            <OutsideClick onClick={() => setIsCtaOpen(false)}>
                <div
                    className="cta__trigger"
                    onClick={() => setIsCtaOpen((prevState) => !prevState)}>
                    <span>{isCtaOpen ? 'Collapse' : 'Get Help'}</span>
                    <span style={{marginTop: '4px'}}>
                        <IconChevron
                            fill="var(--interactive-300)"
                            width="28"
                            height="28"
                            rotate={isCtaOpen ? '180' : '0'}
                        />
                    </span>
                </div>
                {isCtaOpen && (
                    <ABTest
                        id="AAC2-5772"
                        Control={() => (
                            <div className="cta__content">
                                <SharedModalContent />
                            </div>
                        )}
                        variations={[() => <GetHelpMobileCta />]}
                    />
                )}
            </OutsideClick>
            <style jsx>{`
                .cta__trigger {
                    background: var(--gray-500);
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-gap: 4px;
                    height: 56px;
                    cursor: pointer;
                    border-bottom: 1px solid var(--gray-400);
                }
                .cta {
                    position: relative;
                }
                .cta__content {
                    position: fixed;
                    top: 266px;
                    bottom: 0;
                    overflow-y: scroll;
                    z-index: 100;
                }
            `}</style>
        </div>
    );
};
export default GetHelpModalMobile;
