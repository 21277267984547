import IconChat from 'aac-components/components/Icons/Communication/IconChat';
import IconSearch from 'aac-components/components/Icons/Basic/IconSearch';
import {useContext, useEffect, useState} from 'react';
import SiteSearch from '../../../SiteSearch';
import IconMenu from 'aac-components/components/Icons/Basic/IconMenu';
import IconClose from 'aac-components/components/Icons/Basic/IconClose';
import styled from 'styled-components';
import OutsideClick from 'aac-components/components/OutsideClick';
import {useRouter} from 'next/router';
import DriftContext from '../../../Drift/DriftContext';
import {launchChat} from '../../../Drift/helpers';
import AppContext from '../../../AppContext';
import {NavContext} from '../..';

const MobileNavTriggers = () => {
    const [showSeachBar, setShowSearchBar] = useState(false);

    const {driftLoaded} = useContext(DriftContext);

    const {isNavDrawerOpen, setIsNavDrawerOpen, showNextStepsNav} =
        useContext(NavContext);

    const {
        pageProps: {data: {template = ''} = {}},
    } = useContext(AppContext);

    const {asPath} = useRouter();

    useEffect(() => {});

    // hide other elements when search is open
    useEffect(() => {
        const geoTab = document && document.getElementById('geotarget-tab');
        if (showSeachBar) {
            geoTab && geoTab.setAttribute('style', 'display: none;');
        } else {
            geoTab && geoTab.setAttribute('style', 'display: flex;');
        }
    }, [showSeachBar]);

    const isCompliancePage = template === 'compliance.php';

    return (
        <div className="nav-triggers">
            <OutsideClick onClick={() => setShowSearchBar(false)}>
                <div className="nav-triggers__container">
                    <div className="nav-triggers__icon--chat">
                        {driftLoaded && !isCompliancePage && (
                            <div onClick={launchChat}>
                                <IconChat fill="var(--primary)" width="20" height="20" />
                            </div>
                        )}
                    </div>
                    <div
                        className="nav-triggers__icon--search"
                        onClick={() => setShowSearchBar((prevInput) => !prevInput)}>
                        <IconSearch fill="var(--primary)" width="20" height="20" />
                    </div>
                    <div
                        className="nav-triggers__icon--menu"
                        onClick={() =>
                            setIsNavDrawerOpen((prevInputState) => !prevInputState)
                        }>
                        {isNavDrawerOpen ? (
                            <IconClose fill="var(--primary)" width="22" height="22" />
                        ) : (
                            <IconMenu fill="var(--primary)" width="22" height="22" />
                        )}
                    </div>
                </div>
                <div className={`nav-triggers__search ${showSeachBar ? 'show' : ''}`}>
                    <SiteSearchStyles>
                        <SiteSearch />
                    </SiteSearchStyles>
                </div>
            </OutsideClick>
            <style jsx>
                {`
                    .nav-triggers {
                        position: relative;
                    }
                    .nav-triggers__container {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        justify-content: center;
                        text-align: center;
                        align-items: center;
                        min-width: 100%;
                    }
                    .nav-triggers__container > div {
                        width: 100%;
                        display: flex;
                        height: 55px;
                        align-items: center;
                        justify-content: center;
                    }
                    .nav-triggers__search {
                        position: fixed;
                        padding: 16px;
                        background: #fff;
                        min-width: 100%;
                        left: 0;
                        border-bottom: 1px solid var(--gray-300);
                        z-index: -1;
                        transition: all 0.25s;
                        transform: translateY(-100%);
                    }
                    .nav-triggers__search.show {
                        background: #fff;
                        transform: ${showNextStepsNav
                            ? 'translateY(85%)'
                            : 'translateY(0%)'};
                    }
                `}
            </style>
        </div>
    );
};
export default MobileNavTriggers;

const SiteSearchStyles = styled.div`
    .site-search {
        box-shadow: none !important;
        border: 1px solid var(--gray-200) !important;
        border-radius: 8px !important;
    }
    form {
        border-radius: 8px !important;
    }
    input#site-search {
        border-radius: 8px !important;
    }
`;
