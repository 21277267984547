import {useRouter} from 'next/router';
import {useContext, useEffect} from 'react';
import AppContext from '../AppContext';
import url from 'url';

const RequestNotificationPermission = () => {
    const {asPath} = useRouter();
    const path = url?.parse(asPath)?.pathname;
    const {
        sessionStorageState: {
            geolocation: {facility = '', location = '', stateName = ''},
        } = {},
    } = ({} = useContext(AppContext));

    const trackingInfo = {
        ['path']: path,
        ['geoloc-facility']: facility,
        ['geoloc-location']: location,
        ['geoloc-state']: stateName,
    };

    useEffect(() => {
        const delay = setTimeout(() => {
            if (window?.Notification?.permission === 'default') {
                // track in heap
                window?.heap?.track('Notification Permission Requested', trackingInfo);

                window?.Notification?.requestPermission((permission) => {
                    // track in heap
                    window?.heap?.track('Notification Permission Response', {
                        permission,
                        ...trackingInfo,
                    });
                });
            }
        }, 10000);
        return () => clearTimeout(delay);
    });
    return null;
};
export default RequestNotificationPermission;
