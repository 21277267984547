import {BREAKPOINT} from '../../lib/styles';
import {useContext, useEffect, useState} from 'react';
import {getTags} from '../../lib/utils';
import VeteransCta from '../VeteransCta';
import {useRouter} from 'next/router';
import AppContext from '../AppContext';
import Image from 'next/image';
import CallrailLink from '../CallrailLink';
import Link from 'next/link';

const PreFooter = (props) => {
    const {prefooterbg} = props;

    const {pageProps, isMobile} = useContext(AppContext);
    const {
        data: {
            acf: {hide_global_ctas: {pre_footer: hidePreFooter} = {}} = {},
            template = '',
        } = {},
    } = pageProps;

    const [isVeteransPage, setIsVeteransPage] = useState(false);
    const {asPath} = useRouter();

    useEffect(() => {
        const tags = getTags();
        setIsVeteransPage(Array.isArray(tags) && tags.includes('veterans'));
    }, [asPath]);
    if (hidePreFooter || template === 'compliance.php') return null;
    return (
        <section id="prefooter">
            <div className="prefooter__container">
                {isVeteransPage ? (
                    <VeteransCta />
                ) : (
                    <div>
                        <div className="prefooter__title">Need more info?</div>
                        <div className="prefooter__copy"></div>
                        <div className="prefooter__options">
                            <div>
                                <div className="prefooter__option--illustration">
                                    <Image
                                        src="/static/prefooter/contact-us-illustration.png"
                                        alt="American Addiction Centers Photo"
                                        width={0}
                                        height={0}
                                        sizes="160px"
                                        style={{width: '160px', height: 'auto'}}
                                    />
                                </div>
                                <div className="prefooter__option--title">
                                    Take the first step {!isMobile && <br />}
                                    towards recovery.
                                </div>
                                <div className="prefooter__option--link">
                                    <CallrailLink />
                                </div>
                            </div>
                            <div>
                                <div className="prefooter__option--illustration">
                                    <Image
                                        src="/static/prefooter/verify-insurance-illustration.png"
                                        alt="American Addiction Centers Photo"
                                        width={0}
                                        height={0}
                                        sizes="160px"
                                        style={{width: '160px', height: 'auto'}}
                                    />
                                </div>
                                <div className="prefooter__option--title">
                                    Make the process simple. Ensure your benefits cover
                                    treatment.
                                </div>
                                <div
                                    className="prefooter__option--link"
                                    onClick={(e) => window?.AAC?.openSlideInSsvob(e)}>
                                    Check Insurance Online
                                </div>
                            </div>
                            <div>
                                <div className="prefooter__option--illustration">
                                    <Image
                                        src="/static/homepage-assets/treatment-options.png"
                                        alt="American Addiction Centers Photo"
                                        width={0}
                                        height={0}
                                        sizes="160px"
                                        style={{width: '160px', height: 'auto'}}
                                    />
                                </div>
                                <div className="prefooter__option--title">
                                    Explore American Addiction Centers locations
                                    nationwide.
                                </div>
                                <Link
                                    href="/treatment-centers"
                                    title="View Our Treatment Cetners"
                                    style={{
                                        textAlign: 'center',
                                        display: 'block',
                                        fontWeight: 'bold',
                                    }}>
                                    View Our Treatment Centers
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <style jsx>{`
                #prefooter {
                    background: ${prefooterbg || '#fff'};
                    height: auto;
                    position: relative;
                }

                .prefooter__container {
                    max-width: 1170px;
                    margin: 0 auto;
                    background: #fff;
                    border-top: 2px solid var(--secondary-200);
                    border-radius: none;
                    width: 100%;
                    padding: ${isVeteransPage ? '0' : '40px 16px'};
                    position: relative;
                    z-index: 1;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .prefooter__container {
                        border-radius: 8px;
                        border: 2px solid var(--secondary-200);
                    }
                }
                .prefooter__title {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 16px;
                    text-align: center;
                }
                .prefooter__copy {
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 44px;
                }
                .prefooter__options {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 16px;
                    align-items: center;
                    justify-content: center;
                    grid-gap: 40px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .prefooter__options {
                        grid-template-columns: repeat(3, 280px);
                        grid-gap: 40px;
                    }
                }

                .prefooter__option--illustration {
                    position: relative;
                    width: 160px;
                    height: 160px;
                    margin-bottom: 16px;
                    margin: 0 auto 16px auto;
                }
                .prefooter__option--title {
                    text-align: center;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 8px;
                }
                .prefooter__option--link {
                    text-align: center;
                    cursor: pointer;
                    font-weight: bold;
                    color: var(--interactive);
                    text-decoration: underline;
                }
            `}</style>
        </section>
    );
};

export default PreFooter;
