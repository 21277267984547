import {useContext, useState, useEffect} from 'react';
import {debounce} from 'throttle-debounce';
import useStateIfMounted from '../../../../lib/hooks/useStateIfMounted';
import {NavContext} from '../..';
import Image from 'next/image';
import Link from 'next/link';
import MobileNavTriggers from '../MobileNavTriggers';
import {TableOfContents} from 'aac-components/components/TableOfContents';
import styled from 'styled-components';
import {useRouter} from 'next/router';
import AppContext from '../../../AppContext';
import url from 'url';
import {getBasePath} from '../../../../lib/utils';
import {aacLogoHorizontal} from '../../../../lib/globals';

const stickyTocBlacklist = [
    'treatment-centers',
    'insurance-coverage',
    '90-day-promise',
    'reviews',
    'self-assessment',
];

const MobileNavHeader = () => {
    const {isNavDrawerOpen, setIsNavDrawerOpen} = useContext(NavContext);
    const context = useContext(AppContext);
    const html = context?.pageProps?.data?.content?.rendered || false;
    const [scrollTop, setScrollTop] = useStateIfMounted(0);

    const [headings, setHeadings] = useState([]);

    const router = useRouter();
    const {asPath} = router;
    const basePath = getBasePath(asPath);

    useEffect(() => {
        const headings = Array.from(document.querySelectorAll('h2'));
        setHeadings(headings);
    }, [asPath]);

    // handle showing "Next Steps" in nav when user scrolls down
    // also handle showing TOC in nav when user scrolls down
    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', debounce(100, onScroll));
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    // custom toc scroll
    const scrollToH2 = (ref) => {
        if (typeof window !== 'undefined' && document) {
            const el = ref?.current || ref;
            const headerHeight =
                typeof window !== 'undefined'
                    ? document?.querySelector('header')?.offsetHeight || 0
                    : 0;
            const offset = el.getBoundingClientRect().top + window.scrollY - headerHeight;

            const extraPadding =
                document.getElementById('nav-container').offsetHeight + 50;

            el &&
                window.scrollTo({
                    top: offset - extraPadding,
                    behavior: 'smooth',
                });
        }
    };
    const showTOC = html && scrollTop >= 500 && !stickyTocBlacklist.includes(basePath);

    return (
        <div className="mobile-nav__header">
            <div>
                {showTOC && Array.isArray(headings) && headings.length >= 1 ? (
                    <TableOfContentsStyles>
                        <TableOfContents title="Jump to Section" scrollTo={scrollToH2} />
                    </TableOfContentsStyles>
                ) : (
                    <Link href="/" title="Home">
                        <div className="mobile-nav__logo">
                            <Image
                                src={aacLogoHorizontal}
                                fill
                                style={{objectFit: 'contain'}}
                                priority={true}
                                sizes="300px"
                                alt="American Addiction Centers Image"
                            />
                        </div>
                    </Link>
                )}
            </div>
            <div>
                <MobileNavTriggers />
            </div>

            <style jsx>
                {`
                    .mobile-nav__logo {
                        display: block;
                        max-width: 240px;
                        height: 100%;
                        position: relative;
                        margin-right: 4px;
                        margin: 0 8px;
                    }
                    .mobile-nav__header {
                        height: 56px;
                        background: #fff;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 4px;
                        align-items: center;
                        z-index: 100;
                        border-bottom: 1px solid var(--gray-200);
                    }
                    .mobile-nav__header > div {
                        position: relative;
                        height: 56px;
                        max-width: 100%;
                    }
                `}
            </style>
        </div>
    );
};
export default MobileNavHeader;

const TableOfContentsStyles = styled.div`
    .toc {
        margin: 0 !important;
        position: relative !important;
    }
    .toc__header {
        background: var(--gray-100) !important;
        border: none !important;
        border-radius: 0 !important;
        color: var(--primary) !important;
        padding: 0 16px;
        height: 55px;
        display: grid;
        grid-template-columns: auto 18px;
        border-right: 1px solid var(--gray-200) !important;
    }
    .toc__title {
        font-weight: 400 !important;
        font-size: 12px !important;
    }
    .toc__dropdown {
        margin: 0 !important;
        position: fixed !important;
    }
`;
