import OutsideClick from 'aac-components/components/OutsideClick';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {isMappableArray, zIndex} from 'aac-components/utils/helpers';
import {useEffect, useState} from 'react';

const Filter = ({
    onChange,
    selectedOption,
    label,
    options = states,
    placeholder = 'Select State',
    showOutpatient = true,
}) => {
    const [selection, setSelection] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        onChange && onChange(selection);
    }, [selection]);

    useEffect(() => {
        selectedOption === null && setSelection(null);
    }, [selectedOption]);

    const hasFilteredState =
        selectedOption !== 'View All' &&
        selectedOption !== null &&
        selectedOption !== 'States Not Listed';

    return (
        <div className="filter">
            <div className="filter__label">
                {label}
                {hasFilteredState && (
                    <span onClick={() => setSelection(null)} className="filter__clear">
                        Clear
                    </span>
                )}
            </div>
            <OutsideClick onClick={() => setIsDropdownOpen(false)}>
                <div
                    className="filter__input"
                    onClick={() =>
                        setIsDropdownOpen((prevInputState) => !prevInputState)
                    }>
                    {selection || placeholder}
                    <IconChevron
                        rotate={isDropdownOpen ? '180' : '0'}
                        fill="var(--gray-400)"
                    />
                </div>
                {isDropdownOpen && (
                    <div className="filter__options">
                        {isMappableArray(options) &&
                            options.map((state) => {
                                if (
                                    !showOutpatient &&
                                    state === 'Outpatient & Sober Living'
                                )
                                    return null;
                                return (
                                    <div
                                        key={state}
                                        className="filter__option"
                                        onClick={() => {
                                            setSelection(state);
                                            setIsDropdownOpen(false);
                                        }}
                                        dangerouslySetInnerHTML={{__html: state}}
                                    />
                                );
                            })}
                    </div>
                )}
            </OutsideClick>
            <style jsx>
                {`
                    .filter {
                        position: relative;
                    }
                    .filter__label {
                        margin-bottom: 16px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .filter__input {
                        height: 45px;
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 16px;
                        border: 1px solid var(--gray-300);
                        cursor: pointer;
                        border-radius: ${isDropdownOpen ? '4px 4px 0 0 ' : '4px'};
                    }
                    .filter__options {
                        background: #fff;
                        position: absolute;
                        z-index: ${zIndex('dropdownMenu')};
                        min-width: 100%;
                        padding: 4px 0;
                        border: 1px solid var(--gray-300);
                        border-radius: 0 0 8px 8px;
                        cursor: pointer;
                        box-shadow: 0px 6px 5px rgba(000, 000, 000, 0.05);
                        text-align: left;
                    }
                    .filter__option {
                        margin-bottom: 4px;
                        padding: 4px 16px;
                    }
                    .filter__option:hover {
                        cursor: pointer;
                        background: var(--interactive-100);
                    }
                    .filter__clear {
                        color: var(--secondary-300);
                        font-size: 12px;
                        font-weight: normal;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                `}
            </style>
        </div>
    );
};
export default Filter;

const states = [
    'California',
    'Florida',
    'Massachusetts',
    'Mississippi',
    'Nevada',
    'Rhode Island',
    'Texas',
    'Outpatient & Sober Living',
    'States Not Listed',
    'View All',
];
