import IconClose from 'aac-components/components/Icons/Basic/IconClose';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import SiteSearch from '../SiteSearch';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {zIndex} from 'aac-components/utils/helpers';
import {NavContext} from '.';

// menus
import MainMenuMobile from './MobileNav/MainMenuMobile';
import SecondaryMenuMobile from './MobileNav/SecondaryMenuMobile';
import AppContext from '../AppContext';
import UserWay from 'aac-components/components/UserWay';
import {BREAKPOINT} from '../../lib/styles';
import OutsideClick from 'aac-components/components/OutsideClick';
import DriftContext from '../Drift/DriftContext';

import GetHelpModalMobile from './GetHelpModal/GetHelpModalMobile';
import ABTest from '../ABTest';

const NavDrawer = () => {
    const {
        menu,
        isNavDrawerOpen,
        setIsNavDrawerOpen,
        activeSecondaryMenuId,
        setActiveSecondaryMenuId,
    } = useContext(NavContext);

    const {isChatActive} = useContext(DriftContext);

    const context = useContext(AppContext);
    const {isMobile} = context;

    const [drawerHeight, setDrawerHeight] = useState(0);
    const [drawerMenuHeight, setDrawerMenuHeight] = useState(0);

    const showMainMenu = activeSecondaryMenuId === null;
    const activeSecondaryMenu = menu.filter(
        (item) => item?.ID === activeSecondaryMenuId,
    )?.[0];

    const activeSecondaryMenuIndex = menu.indexOf(activeSecondaryMenu);

    let DRAWER_HEADER_HEIGHT = showMainMenu ? 210 : 122;
    DRAWER_HEADER_HEIGHT = isMobile ? DRAWER_HEADER_HEIGHT : 120;

    let MENU_TITLE_TOP = showMainMenu ? 142 : 54;
    MENU_TITLE_TOP = isMobile ? MENU_TITLE_TOP : 54;

    // scroll menu back to top when user changes SecondaryMenus
    useEffect(() => {
        const scrollableMenu = document.getElementById('nav__menu');
        scrollableMenu.scrollTop = 0;
    }, [activeSecondaryMenuId]);

    // adjust drawer height
    useEffect(() => {
        const drawerHeight = window.innerHeight - 40;

        setDrawerHeight(drawerHeight);
        setDrawerMenuHeight(drawerHeight - DRAWER_HEADER_HEIGHT);
    }, [showMainMenu, isMobile]);

    const menuTitle = activeSecondaryMenu ? activeSecondaryMenu?.title : 'Main Menu';

    // handle closing chat when side nav opens
    const [hasCurrentChatWindow, setHasCurrentChatWindow] = useState(null); // manage this just in this component
    useEffect(() => {
        if (!isMobile) {
            if (isNavDrawerOpen) {
                setHasCurrentChatWindow(isChatActive);
                window?.drift?.api && window?.drift?.api.hideChat();
            }
            if (!isNavDrawerOpen && hasCurrentChatWindow) {
                window?.drift?.api && window?.drift?.api.openChat();
            }
        }
    }, [isNavDrawerOpen]);

    return (
        <div className="nav">
            <OutsideClick onClick={() => setIsNavDrawerOpen(false)}>
                <nav
                    id="nav-menu"
                    className={`nav__drawer ${isNavDrawerOpen ? 'show' : ''}`}>
                    <div className="nav__drawer--header">
                        <div className="nav__back">
                            {showMainMenu && (
                                <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        setActiveSecondaryMenuId(null),
                                            setIsNavDrawerOpen(false);
                                    }}>
                                    <IconClose
                                        fill="var(--interactive)"
                                        width="22"
                                        height="22"
                                    />{' '}
                                    <span>Close Main Menu</span>
                                </div>
                            )}
                            {!showMainMenu && (
                                <div onClick={() => setActiveSecondaryMenuId(null)}>
                                    <IconChevron
                                        fill="var(--interactive)"
                                        width="22"
                                        height="22"
                                        rotate="90"
                                    />{' '}
                                    <span>Back to Main Menu</span>
                                </div>
                            )}
                        </div>
                        {isMobile && showMainMenu && (
                            <div className="nav__search">
                                <SiteSearchStyles>
                                    <SiteSearch />
                                </SiteSearchStyles>
                            </div>
                        )}
                        <div className="nav__menu--title">
                            <div dangerouslySetInnerHTML={{__html: menuTitle}} />
                            <div className="userway-container">
                                {isMobile && <UserWay />}
                            </div>
                        </div>
                    </div>

                    <div id="nav__menu" className="nav__menu">
                        {isMobile && (
                            <div style={{display: `${showMainMenu ? 'block' : 'none'}`}}>
                                <GetHelpModalMobile />
                            </div>
                        )}
                        <MainMenuMobile
                            menu={menu}
                            show={showMainMenu && !activeSecondaryMenu}
                            menuHeight={drawerMenuHeight}
                            setActiveSecondaryMenuId={setActiveSecondaryMenuId}
                            activeSecondaryMenuIndex={activeSecondaryMenuIndex}
                        />
                        <SecondaryMenuMobile
                            menu={menu}
                            show={!showMainMenu && activeSecondaryMenu}
                            menuHeight={drawerMenuHeight}
                            activeSecondaryMenuIndex={activeSecondaryMenuIndex}
                        />
                    </div>
                </nav>
            </OutsideClick>

            <style jsx>
                {`
                    .nav__drawer {
                        background: #fff;
                        transform: translateX(100%);
                        top: 0px;
                        right: 0;
                        bottom: 0;
                        position: fixed;
                        overflow-y: hidden;
                        overflow-x: hidden;
                        z-index: ${zIndex('navigationOpen')};
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .nav__drawer {
                            max-width: 450px;
                            box-shadow: var(--box-shadow);
                        }
                    }
                    .nav__drawer.show {
                        transform: translateX(0);
                        transition: all 0.2s ease-in;
                        width: 100%;
                    }
                    .nav__drawer--header {
                        width: 100%;
                        height: ${DRAWER_HEADER_HEIGHT}px;
                        position: relative;
                    }
                    .nav__back {
                        background: var(--primary);
                        color: #fff;
                        padding: 16px;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        cursor: pointer;
                    }
                    .nav__back div {
                        display: flex;
                        align-items: center;
                    }
                    .nav__back span {
                        margin-left: 14px;
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                    .nav__search {
                        position: absolute;
                        top: 54px;
                        width: 100%;
                    }
                    .nav__menu {
                        position: relative;
                        height: 100%;
                        overflow: hidden;
                    }
                    .nav__menu--title {
                        position: absolute;
                        top: ${MENU_TITLE_TOP}px;
                        width: 100%;
                        padding: 16px;
                        font-size: 24px;
                        font-weight: bold;
                        display: flex;
                    }
                    .nav__menu--title > div:first-child {
                        margin-right: 16px;
                    }
                    .nav__menu--title > .userway-container {
                        position: relative;
                    }
                `}
            </style>
        </div>
    );
};
export default NavDrawer;

export const SiteSearchStyles = styled.div`
    .site-search {
        box-shadow: none !important;
    }
    .site-search form {
        margin: 16px !important;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .site-search {
            width: 100% !important;
            border-radius: 4px !important;
        }
        .site-search form {
            margin: 0px !important;
            border-radius: 4px !important;
        }
        .site-search input#site-search {
            width: 70% !important;
            min-width: 70% !important;
        }
    }
`;
